<template>
  <div>
    <div style="padding: 5px 0">
      <el-button @click="add" type="primary" size="mini" style="margin: 10px"
        >新增</el-button
      >
    </div>
    <el-table :data="tableData" border stripe style="width: 100%">
      <el-table-column prop="detailId" label="ID" width="100" sortable>
      </el-table-column>
      <el-table-column
        prop="title"
        label="商品名称"
        width="150"
      ></el-table-column>
      <el-table-column label="图片">
        <template slot-scope="scope">
          <el-image
            style="width: 50px; height: 50px"
            :src="scope.row.titleImg"
            :preview-src-list="[scope.row.titleImg]"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column label="分类">
        <template slot-scope="scope">
          {{ scope.row.categoryName }}
        </template>
      </el-table-column>
      <el-table-column
        prop="descs"
        label="商品描述"
        width="300"
      ></el-table-column>
      <el-table-column label="标签">
        <template slot-scope="scope">
          {{ scope.row.tag.join(",") }}
        </template>
      </el-table-column>
      <el-table-column prop="prePrice" label="原价"></el-table-column>
      <el-table-column prop="price" label="现价"></el-table-column>
      <el-table-column prop="createTime" label="创建时间"></el-table-column>

      <el-table-column fixed="right" label="操作" width="200">
        <template slot-scope="scope">
          <el-button
            type="primary"
            icon="el-icon-edit"
            circle
            @click="edit(scope.row)"
          ></el-button>
          <el-popconfirm @confirm="del(scope.row.detailId)" title="确定删除？">
            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              slot="reference"
              style="margin-left: 10px"
            ></el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <div style="margin-top: 10px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-size="pageSize"
        :page-sizes="[10, 20, 30]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 弹窗   -->
    <el-dialog
      title="信息"
      :visible.sync="dialogFormVisible"
      width="50%"
      :close-on-click-modal="false"
    >
      <el-form :model="entity">
        <el-form-item label="商品名称" label-width="150px">
          <el-input
            v-model="entity.title"
            autocomplete="off"
            style="width: 80%"
          ></el-input>
        </el-form-item>
        <el-form-item label="商品描述" label-width="150px">
          <el-input
            type="textarea"
            v-model="entity.descs"
            autocomplete="off"
            style="width: 80%"
          ></el-input>
        </el-form-item>
        </el-form-item>
        <el-form-item label="商品详情" label-width="150px">
          <el-input
            type="textarea"
            v-model="entity.detailDesc"
            autocomplete="off"
            style="width: 80%"
          ></el-input>
        </el-form-item>
        <el-form-item label="原价" label-width="150px">
          <el-input
            v-model="entity.prePrice"
            autocomplete="off"
            style="width: 80%"
          ></el-input>
        </el-form-item>
        <el-form-item label="现价" label-width="150px">
          <el-input
            v-model="entity.price"
            autocomplete="off"
            style="width: 80%"
          ></el-input>
        </el-form-item>
        <el-form-item label="分类" label-width="150px">
          <el-select
            v-model="entity.categoryId"
            placeholder="请选择"
            style="width: 80%"
          >
            <el-option
              v-for="item in options"
              :key="item.categoryId"
              :label="item.name"
              :value="item.categoryId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="标签" label-width="150px">
          <el-select
            v-model="entity.tag"
            multiple
            filterable
            remote
            reserve-keyword
            remote-show-suffix
            placeholder="请选择标签"
            :remote-method="getTagList"
            style="width: 100%"
          >
            <el-option
              v-for="item in tagOptions"
              :key="item.tag_name"
              :label="item.tag_name"
              :value="item.tag_name"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="图片" label-width="150px">
          <el-input
            v-model="entity.titleImg"
            autocomplete="off"
            style="width: 80%"
          ></el-input>
          <el-upload
            action="https://shop.bochi.ink:3303/api/back/upload"
            multiple
            :on-success="handleSuccess"
            ref="upload"
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import API from "../utils/request";
const url = "/api/goods/";

export default {
  name: "Goods",
  data() {
    return {
      fileList: [],
      options: [],
      text: "",
      user: {},
      tableData: [],
      entity: {},
      total: 0,
      tagOptions: [],
      dialogFormVisible: false,
      stat: 0,
      pageNum: 1,
      pageSize: 10,
    };
  },
  created() {
    this.user = sessionStorage.getItem("user")
      ? JSON.parse(sessionStorage.getItem("user"))
      : {};
    this.load();
  },
  methods: {
    handleSuccess(res) {
      this.entity.titleImg = res.temp_pathList[0];
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.load();
    },
    handleCurrentChange(pageNum) {
      this.pageNum = pageNum;
      this.load();
    },
    async load() {
      await API.get("/api/back/category?type=category").then((res) => {
        this.options = res.data;
        API.get(
          `/api/back/select/shop?type=0&pageNum=${this.pageNum}&pageSize=${this.pageSize}`
        ).then((res) => {
          this.tableData = res.data || [];
          this.total = res.total;
          this.tableData.forEach((item) => {
            // 处理下表格的图片显示
            if (!item.imgs) {
              item.imgs = [""];
            } else {
              item.imgs = JSON.parse(item.imgs);
            }
            item.categoryName = this.options.find(
              (el) => el.categoryId === item.categoryId
            )?.name;
            item.tag = item.tag.split(",");
          });
        });
      });
    },
    getTagList(e) {
      API.get("/api/back/tags/getAll").then((res) => {
        if (e) {
          this.tagOptions = res.data.filter((item) => {
            return item.tag_name.includes(e);
          });
        } else {
          this.tagOptions = res.data;
        }
      });
    },
    add() {
      this.stat = 1;
      this.entity = {};
      this.fileList = [];
      if (this.$refs["upload"]) {
        this.$refs["upload"].clearFiles();
      }
      this.dialogFormVisible = true;
    },
    edit(obj) {
      this.stat = 2;
      console.log(this.$refs);
      this.entity = JSON.parse(JSON.stringify(obj));
      this.fileList = [];
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        if (this.$refs["upload"]) {
          this.$refs["upload"].clearFiles();
        }
      });
    },
    save() {
      if (this.fileList.length) {
        this.entity.imgs = JSON.stringify(this.fileList);
      }
      if (typeof this.entity.imgs === "object") {
        this.entity.imgs = JSON.stringify(this.entity.imgs);
      }
      if (this.stat === 1) {
        API.post(`/api/back/add/shop`, this.entity).then((res) => {
          if (res.code === 200) {
            this.$message({
              type: "success",
              message: "操作成功",
            });
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
          this.load();
          this.dialogFormVisible = false;
        });
      } else if (this.stat === 2) {
        API.post(`/api/back/update/shop/base/info`, this.entity).then((res) => {
          if (res.code === 200) {
            this.$message({
              type: "success",
              message: "操作成功",
            });
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
          this.load();
          this.dialogFormVisible = false;
        });
      }
    },
    del(id) {
      API.post(`/api/back/delete/shop`, { detailId: id }).then((res) => {
        this.$message({
          type: "success",
          message: "操作成功",
        });
        this.load();
      });
    },
  },
};
</script>

<style scoped>
</style>
